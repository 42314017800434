import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Link } from 'react-router-dom'
import { messageResources } from '../../assets/util/messageResources'
import { selectAllStudents } from '../../redux/student/studentSlice'
import { useSelector } from 'react-redux'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'


const printListComa = (list) => {

	
    var toPrint = "";
    list.map((item) => {
        toPrint += `${item.label}, `
        //console.log(toPrint)
    })   

    if(toPrint.trim().endsWith(',')) { return toPrint.slice(0, -2)} else { return toPrint }
                        
}



const StudentsData = () => {

    //console.log("|-----> StudentsList COMPONENT StudentsList <------|")

    const columnsForAllStudents = [
        
        { title: "Name",  field: "name", defaultSort: "asc", render:rowData=><Link to={`/students/${rowData._id}`}> {rowData.name} </Link> }, 
        { title: "Surname",  field: "surname", defaultSort: "asc", render:rowData=><ClipboardCopy copyText={rowData.surname} /> }, 
        { title: "Email",  field: "email", render:rowData=><ClipboardCopy copyText={rowData.email} />  },
        { title: "Contact Number",  field: "contact_number" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.contact_number} />},
        { title: "Date of Birth", field: "date_birth", type: 'date', dateSetting: { format: 'dd/MM/yyyy'}, hidden: true, hiddenByColumnsButton: true},
        { title: "Age",  field: "age", hidden: true, hiddenByColumnsButton: true},
        { title: "Ethnicity",  field: "ethnicity.value", render:rowData=><ClipboardCopy copyText={rowData.ethnicity.value} />  },
        { title: "NIN",  field: "nin", render:rowData=><ClipboardCopy copyText={rowData.nin} />   },
        { title: "Careers Docs",  field: "carDocs", render:rowData=><ClipboardCopy copyText={rowData.carDocs} />,hidden: true, hiddenByColumnsButton: true },
        { title: "NOK Emails",  field: "kinEm", render:rowData=><ClipboardCopy copyText={rowData.kinEm} /> ,hidden: true, hiddenByColumnsButton: true},
        { title: "NOK",  field: "kinToShow", render:rowData=><ClipboardCopy copyText={rowData.kinToShow} /> ,hidden: true, hiddenByColumnsButton: true},
        { title: "Passport Number",  field: "passport_number" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.passport_number} />},
        { title: "Passport Expiry", field: "passport_expiry", type: 'date', dateSetting: { format: 'dd/MM/yyyy'} },
        { title: "Borough",  field: "borough.value", render:rowData=><ClipboardCopy copyText={rowData.borough.value} />, hidden: true, hiddenByColumnsButton: true   },
        { title: "Address",  field: "address.address1", render:rowData=><ClipboardCopy copyText={`${rowData.address?.address1}  ${rowData.address?.address2}  ${rowData.address?.postcode}  ${rowData.address?.city}`} />, hidden: true, hiddenByColumnsButton: true   },
        { title: "Independent Traveller",  field: "taxi.isIndependent", render:rowData=> rowData.taxi?.isIndependent ? "YES" : "NO", hidden: true, hiddenByColumnsButton: true },
        { title: "Medical Condition",  field: "medicalCon", render:rowData=> rowData.medical?.condition ? <ClipboardCopy copyText={printListComa(rowData.medical?.condition)} /> : "", hidden: true, hiddenByColumnsButton: true   },
        { title: "Dietary Requirements",  field: "medicalDiet", render:rowData=> rowData.medical?.dietary ? <ClipboardCopy copyText={printListComa(rowData.medical?.dietary)} /> : "", hidden: true, hiddenByColumnsButton: true   },
        { title: "Medication",  field: "medical.medication", width: "110%", render:rowData=> <ClipboardCopy copyText={rowData.medical?.medication} />, hidden: true, hiddenByColumnsButton: true   },
        { title: "Medical Administration",  field: "medical.assistance", render:rowData=> <ClipboardCopy copyText={rowData.medical?.assistance} />, hidden: true, hiddenByColumnsButton: true   },
        { title: "Medical Notes",  field: "medical.notes", render:rowData=> <ClipboardCopy copyText={rowData.medical?.notes} />, hidden: true, hiddenByColumnsButton: true   },
    ];

    const allOfThem = useSelector(selectAllStudents)
    

    return ( 

        <>    
            <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.STU_DATA} (${allOfThem.length} records)`}/>
            <div className="row">
                <div className="col-md-12">
                    <List   titleForTable={messageResources.BANNER.USE_BUTTON}
                            dataForTable={JSON.parse(JSON.stringify(allOfThem))} 
                            columnsForTable={columnsForAllStudents}
                            search={false}
                            colButton={true}
                            filter={true}
                            pageSize={allOfThem.length} />
                </div>
            </div>
        </>

    
    )
}

export default StudentsData
