import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select'
import axios from 'axios'
import Toggle from 'react-toggle'
import { useNavigate } from "react-router-dom";
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useForm, Controller,FormProvider } from "react-hook-form"
import { editStaffSchema } from '../../assets/util/yupValidations'
import { yupResolver } from "@hookform/resolvers/yup"
import useAuth from "../../hooks/useAuth"
import InputText from "../forms/Input/InputText";
import { roleOptions, positionOptions } from "../../assets/util/selectOptions";
import { useUpdateStaffMutation } from "../../redux/staff/staffApiSlice";

export default function EditStaffModal({staffInfo, header, stuId, rowTook}) {

    //console.log("------> EditStaffModal EditStaffModal <----|")

    const { roles } = useAuth()
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let rolesAdapted = []
    let staffToShow = {}



    function SortArray(x, y){
        return x.label.localeCompare(y.label);
    }

    const positionSorted = positionOptions.sort(SortArray);

    useEffect(() => {

        // console.log("------> EditStaffModal UseEffect <----|")
        // console.log(staffInfo)
        
        staffInfo?.role.map(rol => {
            rolesAdapted.push(roleOptions[rol-1])
        })
        staffToShow.name = staffInfo?.name
        staffToShow.surname = staffInfo?.surname
        staffToShow.address = staffInfo?.address
        staffToShow.contact_number = staffInfo?.contact_number
        staffToShow.name = staffInfo?.name
        staffToShow.position = staffInfo?.position
        staffToShow.roleToShow = rolesAdapted
        staffToShow.picture_path = staffInfo?.picture_path
        staffToShow.isActive = staffInfo?.isActive
        // NOT THE BEST SOLUTION BUT TOGGLE IS NOT TAKING THE BOOLEAN
        staffToShow.isActive && setActive(true)

        reset(staffToShow)
            

	}, [staffInfo]);

    const methods = useForm({
        resolver: yupResolver(editStaffSchema),
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    //console.log(errors)


    const [updateStaff] = useUpdateStaffMutation()


    const onSubmit = async (formData) => {

        //console.log(formData);
        console.log("NO ERRORS")

		try{
            
            formData._id = staffInfo._id

            if(formData.address && formData.address.address1 != null && formData.address.address1 != ""){

                const resFromMaps = await axios.get(`
                https://api.opencagedata.com/geocode/v1/json?q=${formData.address.address1} ${formData.address.address2} ${formData.address.postcode} United Kingdom&key=c0348377c7af49c29b5b501efa40474b`)
    
                //console.log("------> RES FROM MAPS <---------")
                //console.log(resFromMaps?.data);
                // console.log(resFromMaps?.data.results[0].geometry.lat)
                // console.log(resFromMaps?.data.results[0].geometry.lng)

                formData.address.lat = resFromMaps?.data.results[0].geometry.lat;
                formData.address.long = resFromMaps?.data.results[0].geometry.lng

                resFromMaps?.data.results[0].components?.town 
                        ? formData.address.city = resFromMaps?.data.results[0].components?.town
                        : resFromMaps?.data.results[0].components?.city 
                            ? formData.address.city = resFromMaps?.data.results[0].components?.city
                            : formData.address.city = ""
        
        
            }else{
                formData.address = null
            }
            

            if(formData.picture[0] && formData.picture[0].size > 0){ 

                //console.log("<><><><><><> UPLOADING PICTURE  <><><><><><>")
                // DATA FOR CLOUDINARY
                const pictureData = new FormData()
                pictureData.append("file",formData.picture[0])
                // uload preset name set in clooudinary
                pictureData.append("upload_preset","roots-pic")
                // given when signing up with cloudinary
                pictureData.append("cloud_name","dlkpi3swo")
                const  resFromCloudinary = await axios.post("https://api.cloudinary.com/v1_1/dlkpi3swo/image/upload",pictureData);
                //console.log(resFromCloudinary);
                //console.log(resFromCloudinary.data.url);
                formData.picture_path = resFromCloudinary.data.url;
    
            }

            let rolesNumber = []
            formData.roleToShow.map(uno => {
                rolesNumber.push(uno.value)
            })

            formData.role = rolesNumber

            await updateStaff(formData).unwrap()
            toast.success(messageResources.TOASTY.STAFF_EDITED)
            setShow(false)
            navigate(`/staff/${staffInfo._id}`)
        
		}catch(err){
			console.log(err);
		}
	}



    return (
        <>
        
        <div className="profile-userbuttons" style={{width: rowTook}}>
            <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                {messageResources.MODAL.EDIT_PROFILE}
            </button>
        </div>

        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    
                            <div className="card card-box">
                                <div className="card-body " id="bar-parent">
                                <div className="row">
                                    <InputText 
                                        slots="col-md-6"
                                        label={messageResources.COMMON.NAME}
                                        errors={errors}
                                        register={register}
                                        type="text"
                                        plaHolder={messageResources.COMMON.NAME}
                                        inputName="name"
                                        original={false}
                                    />
                                    <InputText 
                                        slots="col-md-6"
                                        label={messageResources.COMMON.SURNAME}
                                        errors={errors}
                                        register={register}
                                        type="text"
                                        plaHolder={messageResources.COMMON.SURNAME}
                                        inputName="surname"
                                        original={false}
                                    />
                                </div>
                                <InputText 
                                    slots="col-md-12"
                                    label={messageResources.CONTACT_INFO.ADDRESS_1}
                                    errors={errors}
                                    register={register}
                                    type="text"
                                    plaHolder={messageResources.CONTACT_INFO.ADDRESS_1}
                                    inputName="address.address1"
                                    original={false}
                                />
                                <div className="row">
                                    <InputText 
                                        slots="col-md-6"
                                        label={messageResources.CONTACT_INFO.ADDRESS_2}
                                        errors={errors}
                                        register={register}
                                        type="text"
                                        plaHolder={messageResources.CONTACT_INFO.ADDRESS_2}
                                        inputName="address.address2"
                                        original={false}
                                    />
                                    <InputText 
                                        slots="col-md-6"
                                        label={messageResources.CONTACT_INFO.POSTCODE}
                                        errors={errors}
                                        register={register}
                                        type="text"
                                        plaHolder={messageResources.CONTACT_INFO.POSTCODE}
                                        inputName="address.postcode"
                                        original={false}
                                    />
                                </div>
                                <div className="row">
                                    <InputText 
                                        slots="col-md-6"
                                        label={messageResources.CONTACT_INFO.NUMBER}
                                        errors={errors}
                                        register={register}
                                        type="text"
                                        plaHolder={messageResources.COMMON.SURNAME}
                                        inputName="contact_number"
                                        original={false}
                                    />
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >{messageResources.COMMON.PICTURE}</label>
                                            <input 	type="file" 
                                                    className="form-control" 
                                                    {...register('picture')}/>
                                        </div>
                                    </div>
                                </div>
                                {roles.includes(2) && 
                                    <>
                                    
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{messageResources.STAFF.ROLE}
                                                {errors?.roleToShow && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                            </label>
                                            <Controller
                                                name="roleToShow"
                                                control={control}
                                                render={({ field }) => (
                                                <Select options={roleOptions} 
                                                    isMulti
                                                    defaultValue={staffToShow?.roleToShow}
                                                    {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{messageResources.STAFF.POSITION}
                                                    {errors?.position && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                </label>
                                                <Controller
                                                    name="position"
                                                    control={control}
                                                    render={({ field }) => (
                                                    <Select options={positionSorted} 
                                                        {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{messageResources.STAFF.DEACTIVATE}</label>
                                                    <Controller
                                                        control={control}
                                                        name='isActive'
                                                        render={({ field }) => (
                                                            <Toggle
                                                                defaultChecked={active}
                                                                aria-label='No label tag'
                                                                onChange={(value) => field.onChange(value)}
                                                                value={staffToShow?.isActive ? staffToShow?.isActive : field.value}
                                                                //checked={staffToShow.isActive}
                                                            />
                                                        )}
                                                    />
                                            
                                                    
                                            </div>
                                        </div>
                                    </div>    
                                    </>
                                }
                            
                            </div>
                        </div>
                        <div className="fake-modal-footer">
                            <Button variant="secondary" onClick={handleClose}>
                                {messageResources.BUTTON.CANCEL}
                            </Button>
                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                    {messageResources.BUTTON.AMEND}
                            </button>
                        </div>
                    </form>
                </FormProvider>

            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}